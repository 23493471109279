import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import _Store from '_Store';
import { axius, helpers, types } from 'helpers';
import queryString from 'query-string';

const Header = React.lazy(() => import('./Header'));
const Compliance = React.lazy(() => import('../pages/Compliance'));

const Leyout = ({ children }: { children: any }) => {

    const qs = queryString.parse(window.location.search) as any;

    const location = useLocation();
    const navigate = useNavigate();

    const { _store: { _auth: { user, token } }, _setUser, _setData } = React.useContext(_Store);

    const isLoggedIn = !!(user.id && token && !location.pathname.includes('auth'));
    const isCompliant = !!(user.compliance?.email && user.compliance?.phone_number);

    if (qs.sso) {
        navigate('./', { replace: true });
    }

    React.useEffect(() => {
        if (token) {
            getMyDetails();
        }
        axius.get('products').then(res => {
            _setData({
                key: 'products',
                value: ((res.data.data || []) as types.Product[]).filter(p => p.code !== 'ANCHORA'),
            });
        });
        // eslint-disable-next-line 
    }, []);

    React.useEffect(() => {
        const signup = helpers.getStorageJson('signup') || {};
        const product = helpers.getStorage('product');
        if (isCompliant && signup.name && product.includes('apk')) {
            helpers.delStorage('signup');
            helpers.redirect(product + `?sso=${token}`);
        }
        // eslint-disable-next-line
    }, [isCompliant]);

    const getMyDetails = () => {
        axius.get(`users/me`).then(res => {
            res.status === 200 && _setUser(res.data);
        });
    }

    return (
        <React.Fragment>
            {isLoggedIn ? (
                <>
                    <Header />
                    <div style={{ minHeight: 'calc(100vh - 135px)' }} className="py-16">
                        {isCompliant ? (
                            <>
                                <div>{children}</div>
                            </>
                        ) : (
                            <div className="hidden-">
                                <Compliance />
                            </div>
                        )}
                    </div>
                    <div className="text-center bg-gray-100 py-4 text-gray-500 text-sm">
                        &copy; {moment().format('YYYY')} Anchora Technologies LTD. All rights reserved.
                    </div>
                </>
            ) : (
                <div>{children}</div>
            )}
        </React.Fragment>
    );
}

export default Leyout;