import React from 'react';
import { axius, config, helpers, images, types } from 'helpers';
import _Store from '_Store';
import { Modal, Popover } from 'antd';
import MaterialIcon from './MaterialIcon';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Alert from './Alert';


const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { _store: { _auth: { user, token }, _data: { products } } } = React.useContext(_Store);

  const name = user.name?.split(' ');
  const qwerty = `justify-center items-center h-[40px] w-[40px] cursor-pointer p-1 rounded-full hover:bg-gray-200 transi`;

  const goToApp = (prd: types.Product) => {
    if (prd.url) {
      if (prd.requires_document && !user.compliance?.document?.status) {
        Modal.confirm({
          icon: null,
          title: `Identity Verification`,
          width: 350,
          content: `${prd.name} requires we verify your identity. We would need more information about you to proceed.`,
          okText: `Sure, Proceed`,
          cancelText: `Cancel`,
          centered: true,
          onOk: () => {
            navigate(`verification`);
          }
        });
      } else {
        helpers.redirect(`${prd.url[config.env]}?sso=${token}`);
      }
    } else {
      return false;
      Modal.info({
        icon: null,
        title: `Request Demo`,
        width: 350,
        content: `We want to show you how ${prd.name} works. Write us at anchoratechs@gmail.com`,
        centered: true,
      });
    }
  }

  const doc = user?.compliance?.document;
  const product = helpers.getStorage('product');
  const appsView: any = 'list';
  const linkClass = `transi hover:text-[#27BA7C] px-4 block mb-2 flex items-center gap-2`;

  return (
    <React.Fragment>
      <div className="sticky top-0 z-10 w-full bg-white border-b">

        <div id="header_desktop" className="flex justify-center shadow-lgs bg-primary-lights-">
          <div className="flex justify-between items-center w-11/12 xl:w-9/12 bg-gray-100s py-6">
            <Link to="/">
              <img src={images.Logo} alt="Anchora Technologies Ltd Logo" className="h-[44px] xl:h-[49px] hidden xl:block" />
              <img src={images.LogoIcon} alt="Anchora Technologies Ltd Logo" className="h-[44px] xl:h-[49px] block xl:hidden" />
            </Link>
            {doc?.files?.front && !doc?.status && location.pathname !== '/verification' && (
              <div className="cursor-pointer pop hidden xl:block" onClick={() => navigate('/verification')}>
                {!doc?.rejection_reason && (
                  <Alert color="warning" className="text-sm" icon="info">Your documents are pending verification</Alert>
                )}
                {doc?.rejection_reason && (
                  <Alert color="danger" className="text-sm" icon="security">{doc?.rejection_reason}</Alert>
                )}
              </div>
            )}
            <div className="flex gap-4 items-center">
              {user.type === types.UserTypes.ADMIN && (
                <>
                  <Popover
                    trigger={['click']} arrow={true}
                    content={(
                      <div className="w-[200px] px-2 pt-6 pb-4 border-4- rounded-xl">
                        <Link to="/users" className={linkClass}><MaterialIcon name="group" className="text-xl" /> Users</Link>
                        <Link to="/verifications" className={linkClass}><MaterialIcon name="security" className="text-xl" />Verifications</Link>
                      </div>
                    )}
                  >
                    <div className={`${qwerty} flex`}>
                      <MaterialIcon name="menu" className="text-3xl" />
                    </div>
                  </Popover>
                  <div className="border-r border-black">&nbsp;</div>
                </>
              )}
              {!product.includes('apk') && (
                <Popover
                  trigger={['click']} arrow={true}
                  content={(
                    <>
                      {appsView === 'list' ? (
                        <div className="w-[350px] p-2 border-4 rounded-xl">
                          {products.map(prd => (
                            <div
                              key={prd.name}
                              className={`flex gap-3 items-center px-4 py-2 rounded-lg transi ${prd.url ? 'pops hover:bg-gray-100 cursor-pointer' : 'opacity-60'}`}
                              onClick={() => goToApp(prd)}
                            >
                              <img src={prd.icon} alt={prd.name} className="h-[30px] p-1 rounded-full border" />
                              <div className="w-10/12">
                                <div className="font-medium text-sm mt-2s">{prd.name}</div>
                                <div className="text-xs mt-2s text-gray-500 truncate">{prd.description}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="w-[350px] px-5 py-8 grid grid-cols-3 gap-6 border-4 rounded-xl">
                          {products.map(prd => (
                            <div
                              key={prd.name}
                              className={`text-center p-2 rounded-lg transi ${prd.url ? 'pop hover:bg-[#27ba7c0f] hover:bg-gray-50 cursor-pointer' : 'opacity-60'}`}
                              onClick={() => goToApp(prd)}
                            >
                              <div className="flex justify-center"><img src={prd.icon} alt={prd.name} className="h-[30px]" /></div>
                              <div className="font-medium text-sm mt-2">{prd.name}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                >
                  <div className={`${qwerty} flex`}>
                    <MaterialIcon name="apps" className="text-3xl" />
                  </div>
                </Popover>
              )}
              <div>
                <Popover
                  trigger="click"
                  content={(
                    <div className="w-[200px]">
                      <div className="px-4 pt-4 text-center">
                        <div className="uppercase font-medium truncate">{user.name}</div>
                        <div className="text-sm text-gray-500 truncate">{user.email}</div>
                      </div>
                      <div className="border-b mt-5 mb-4" />
                      <div className="flex gap-2 items-center text-sm text-gray-500 cursor-pointer py-2 px-4 transi hover:bg-gray-50" onClick={() => navigate('/account')}>
                        <MaterialIcon name="account_circle" />
                        <div>Manage Account</div>
                      </div>
                      <div className="flex gap-2 items-center text-sm text-gray-500 cursor-pointer py-2 px-4 transi hover:bg-gray-50" onClick={axius.signOutSuccess}>
                        <MaterialIcon name="logout" />
                        <div>Logout</div>
                      </div>
                      <div className="pb-5" />
                    </div>
                  )}
                >
                  <div className="flex items-center cursor-pointer">
                    <div className="flex justify-center items-center h-[40px] w-[40px] font-medium p-1 rounded-full bg-gray-100 hover:bg-gray-200 transi uppercase">
                      {user.avatar ? <img src={user.avatar_link} alt={user.name} /> : `${name[0][0]}${((name[1] || '')[0] || '')}`}
                    </div>
                    <MaterialIcon name="keyboard_arrow_down" className="text-xl" />
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>

        <div id="header_mobile" className="justify-between items-center">

        </div>
      </div>
    </React.Fragment>
  );
};
export default Header;
