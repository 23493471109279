import { Status } from 'components';
import { helpers, types } from 'helpers';

export const Users = {
    heading: ['Name', 'Description', 'Credentials', 'Status', 'Date'],
    data: (data: types.User[]) => data.map(row => {
        return {
            id: row.id,
            name: {
                width: 20,
                value: row.name,
            },
            credentials: (
                <>

                </>
            ),
            status: <Status status={0} label={row ? 'Enabled' : 'Disabled'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.created_at),
            },
        };
    })
}

export const Compliance = {
    heading: ['Name', 'E-mail', 'Phone Number', 'Document', 'Status', 'Date'],
    data: (data: types.User[]) => data.map(row => {
        const doc = row.compliance?.document;
        return {
            id: row.id,
            name: {
                width: 30,
                value: row.name,
            },
            email: {
                width: 15,
                value: row.email,
            },
            phone: {
                width: 15,
                value: helpers.format.phoneNumber(row.phone_number),
            },
            document: {
                width: 12,
                value: types.ComplianceDocsTypeLabels[doc?.type] ?? 'N/A',
            },
            status: <Status status={doc?.rejection_reason ? 2 : (doc?.status ? 1 : 0)} label={doc?.rejection_reason ? 'Rejected' : doc?.status ? 'Verified' : 'Pending'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.created_at),
            },
        };
    })
}

export const User = {
    heading: ['Name', 'Email', 'Phone No.', 'Prefs', 'Type', 'Status', 'Date'],
    data: (data: types.User[]) => data.map(row => {
        const type = { [types.UserTypes.USER]: 0, [types.UserTypes.ADMIN]: 1 };
        const status = { [types.UserStatus.KYC]: 0, [types.UserStatus.ACTIVE]: 1, [types.UserStatus.SUSPENDED]: 2 };
        return {
            id: row.id,
            name: {
                width: 16,
                value: row.name,
            },
            email: {
                width: 14,
                value: row.email,
            },
            phone_number: {
                width: 14,
                value: helpers.format.phoneNumber(row.phone_number),
            },
            prefs: {
                width: 21,
                value: (
                    <div>
                        {Object.keys((row.preferences || [])).map(pref => (
                            <div key={pref}>•⁠ {pref}: {typeof row.preferences[pref] === 'object' ? JSON.stringify(row.preferences[pref]) : row.preferences[pref]}</div>
                        ))}
                        <div className="border-b border-dashed my-2" />
                        {Object.keys((row.compliance || [])).filter(c => c !== 'document').map(comp => (
                            <div key={comp}>•⁠ {comp}: {typeof row.compliance[comp] === 'object' ? JSON.stringify(row.compliance[comp]) : +row.compliance[comp]}</div>
                        ))}
                    </div>
                ),
            },
            type: {
                width: 10,
                value: <Status status={type[row.type]} label={helpers.ucFirst(row.type)} hasDot={false} />,
            },
            status: {
                width: 10,
                value: <Status status={status[row.status]} label={helpers.ucFirst(row.status)} />,
            },
            date: {
                width: 15,
                value: helpers.format.date(row.created_at),
            },
        };
    })
};