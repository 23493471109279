import React from 'react';
import { helpers, types } from 'helpers';

const Store = React.createContext({} as types.StoreInterface);

export const StoreProvider = (props: {
    children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {

    const uzer: types.User = helpers.isJson(helpers.getStorageJson('auth').user) ? JSON.parse(helpers.getStorageJson('auth').user) : {};
    const tokin = helpers.isJson(helpers.getStorageJson('auth').token) ? JSON.parse(helpers.getStorageJson('auth').token) : '';
    const products: types.Product[] = helpers.isJson(helpers.getStorageJson('data').products) ? JSON.parse(helpers.getStorageJson('data').products) : [];

    const [user, setUser] = React.useState<types.User>(uzer);
    const [token, setToken] = React.useState(tokin);
    // eslint-disable-next-line
    const [_utils, _] = React.useState({
        limit: 20,
        loading: {
            primary: '',
            spinning: false,
            secondary: '',
        }
    });
    const [_data, setData] = React.useState({
        products,
    });

    const _store = { _auth: { user, token }, _utils, _data };

    const _setUser = (value: types.User) => {
        const auth = helpers.getStorageJson('auth') || {};
        const usar: types.User = helpers.isJson(auth.user) ? JSON.parse(auth.user) : {};
        const newData = { ...usar, ...value };
        auth['user'] = JSON.stringify(newData);
        setUser(newData);
        helpers.setStorageJson('auth', auth);
    }

    const _setToken = (value: string) => {
        const auth = helpers.getStorageJson('auth') || {};
        auth['token'] = JSON.stringify(value);
        setToken(value);
        helpers.setStorageJson('auth', auth);
    }

    const _setAuth = (value: { user: types.User; token: string }) => {
        _setUser(value.user);
        _setToken(value.token);
    }

    // const _setUtils = (payload: { key: string, value: any }) => {
    //     const { key, value } = payload; console.log(payload)
    //     if (typeof value === 'string') {
    //         helpers.setStorage(key, value);
    //         setUtils((prevState) => ({ ...prevState, [key]: value, }));
    //     } else {
    //         helpers.setStorageJson(key, value);
    //         setUtils((prevState) => ({ ...prevState, [key]: { ...prevState[key as keyof typeof prevState] as object, ...value }, }));
    //     }
    // }

    // const _setData = (payload: { key: string, value: any }) => {
    //     const { key, value } = payload;
    //     if (typeof value === 'string') {
    //         helpers.setStorage(key, value);
    //         setData((prevState) => ({ ...prevState, [key]: value, }));
    //     } else {
    //         helpers.setStorageJson(key, value);
    //         setData((prevState) => ({ ...prevState, [key]: { ...prevState[key as keyof typeof prevState] as object, ...value }, }));
    //     }
    // }

    const _setData = (payload: { key: 'products', value: any }) => {
        const { key, value } = payload;
        const dada = helpers.getStorageJson('data') || {};
        const usar = helpers.isJson(dada[key]) ? JSON.parse(dada[key]) : {};
        const newData = helpers.isJson(value) ? { ...usar, ...value } : value;
        dada[key] = JSON.stringify(newData);
        setData(sd => ({ ...sd, [key]: newData }));
        helpers.setStorageJson('data', dada);
    }

    return (
        <Store.Provider value={{ _store, _setAuth, _setUser, _setToken, _setData }}>
            {props.children}
        </Store.Provider>
    );

}

export default Store;