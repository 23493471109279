import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { Loading, Leyout } from './components';
import { axius, helpers, routes, types } from 'helpers';
import Raute from 'Raute';
import queryString from 'query-string';


const App = () => {
    const qs = queryString.parse(window.location.search) as any;

    const user: types.User = helpers.isJson(helpers.getStorageJson('auth').user) ? JSON.parse(helpers.getStorageJson('auth').user) : {};

    if (qs.product) {
        helpers.setStorage('product', qs.product);
        !window?.location?.pathname.toLowerCase().includes('disconnect') && helpers.redirect(window?.location?.origin + window?.location?.pathname);
    }
    if (window?.location?.pathname.toLowerCase().includes('disconnect')) {
        axius.signOutSuccess();
        return null;
    }

    const Layout = () => {
        return (
            <React.Fragment>
                <Leyout>
                    <Outlet />
                </Leyout>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Loading full={true} />
            <BrowserRouter>
                <React.Suspense fallback={<Loading full={true} spinning={true} />}>
                    <Routes>
                        <Route element={<Layout />}>
                            {routes.filter(r => !r.types || r.types.includes(user.type)).map(route => (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={(
                                        <Raute route={route}>
                                            <route.element />
                                        </Raute>
                                    )}
                                />
                            ))}
                        </Route>
                    </Routes>
                </React.Suspense>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default App;